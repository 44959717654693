<template>
    <onboarding-layout
        :loading="true"
        :error-text="errorText"
    />
</template>
<script>
    import OnboardingLayout from '@/layouts/Onboarding'
    import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
    import { logger } from '@/utils/logger'
    import { getApplicantReturning, getNextHomeApplicationAction } from '@/services/api'
    import { sharedPagePaths } from '@/routes/sharedRoutes'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { originationPagePaths } from '@/routes/originationRoutes'
    import { Flows } from '@/flow/flowController'
    import { mailerOriginationPagePaths } from '@/routes/mailerOriginationRoutes'
    import assert from 'assert'
    import { NextHomeApplicationAction } from '@/utils/constants'
    import { mailerOriginationFlow } from '@/flow/mailerOriginationFlow'
    import { originationFlow } from '@/flow/originationFlow'
    import authMixin from '../../mixins/authMixin'
    import { goToInitialPageWithPartialState, reloadPageWithCleanState } from '@/utils/routerUtils'

    export default {
        components: { OnboardingLayout },
        props: {
            returnToken: {
                type: String,
                required: true,
            },
        },
        mixins: [authMixin],
        data() {
            return {
                loading: false, // needed for assert in authMixin
                errorText: '',
            }
        },
        mounted: async function () {
            this.$logEvent('view_origination_return', {
                returnToken: this.returnToken,
            })
            try {
                if (appSessionStorage.getItem(sessionStorageKey.jwtTokens)) {
                    logger.info(`User hit origination return to anything page with jwt tokens. Clearing their stale state and reloading the page to acquire a new session`)
                    return reloadPageWithCleanState()
                }

                const applicantReturningResponse = await getApplicantReturning(this.returnToken)
                logger.info(`Received get applicant returning response upon origination return to anything: ${JSON.stringify(applicantReturningResponse.data)}`)

                if (applicantReturningResponse.data.error === 'PRIOR_APPLICATION_FOUND') {
                    assert(applicantReturningResponse.data.payload.returnToken2, 'no return token provided for prior loan application')
                    logger.info(`Found prior loan application, using return token ${applicantReturningResponse.data.payload.returnToken2}`)
                    return await this.$router.replace({
                        path: sharedPagePaths.PRIORITY_APPLICATION_REDIRECTION,
                        query: { p: applicantReturningResponse.data.payload.returnToken2 },
                    })
                }

                if (applicantReturningResponse.data.error === 'APPLICATION_EXPIRED') {
                    const data = applicantReturningResponse.data
                    assert(data.payload.loanApplicationStatus, 'no loanApplicationStatus provided for expired loan application')
                    logger.info(`Loan application ${data.payload.loanApplicationId} is expired, redirecting applicant to start a new application`)

                    // clear the session storage so we can start a new application, keep info needed for context
                    appSessionStorage.setItem(sessionStorageKey.landingWarning, 'Your prior application expired. Please start a new one.')
                    return goToInitialPageWithPartialState([sessionStorageKey.landingWarning, sessionStorageKey.phoneNumber, sessionStorageKey.loanApplicationId])
                }

                // We assume that the user is in the origination flow, which is true most of the
                // time even if they are a direct mail applicant. Only if a direct mail applicant
                // is returning to the pre-qual page will we change the flow to mailerOrigination,
                // so they can complete that flow, then move to origination.
                appSessionStorage.setItem(sessionStorageKey.currentFlow, Flows.origination)

                const nextApplicationActionResponse = await getNextHomeApplicationAction()
                const nextApplicationAction = nextApplicationActionResponse.data.payload.nextApplicationAction
                logger.log(`Next application action is ${nextApplicationAction}`)

                if (nextApplicationAction === NextHomeApplicationAction.WELCOME) {
                    // This is a bit of a hack. By forwarding the applicant to notarization, we
                    // allow the notary site to takeover. It contains routing logic that will
                    // display the Welcome page if the loan application is approved, which is
                    // the status that causes the backend to request routing to the Welcome page.
                    return await this.$router.replace({ path: originationPagePaths.FORWARD_TO_NOTARIZATION })
                } else if (nextApplicationAction === NextHomeApplicationAction.DENIED) {
                    return await this.$router.replace({ path: sharedPagePaths.LOAN_NOT_APPROVED })
                } else if (nextApplicationAction === NextHomeApplicationAction.NOTARIZATION) {
                    return await this.$router.replace({ path: originationPagePaths.FORWARD_TO_NOTARIZATION })
                } else if (nextApplicationAction === NextHomeApplicationAction.OFFER_PREVIEW) {
                    return await this.$router.replace({ path: originationPagePaths.OFFER_PREVIEW })
                } else if (nextApplicationAction === NextHomeApplicationAction.FLOOD_INSURANCE_VERIFICATION) {
                    return await this.$router.replace({ path: originationPagePaths.FLOOD_INSURANCE_VERIFICATION })
                } else if (nextApplicationAction === NextHomeApplicationAction.POST_ACCEPT) {
                    // this will automagically redirect to either the coOwners OR trust info page as needed
                    return await this.$router.replace({ path: originationPagePaths.SECONDARY_SIGNERS })
                } else if (nextApplicationAction === NextHomeApplicationAction.OFFER_ADD_COAPPLICANT) {
                    return await this.$router.replace({ path: originationPagePaths.OFFER_ADD_CO_APPLICANT_ON_FAILURE })
                } else if (nextApplicationAction === NextHomeApplicationAction.HMDA) {
                    return await this.$router.replace({ path: originationPagePaths.HMDA })
                } else if (nextApplicationAction === NextHomeApplicationAction.PROPERTY_VERIFICATION) {
                    return await this.$router.replace({ path: originationPagePaths.PROPERTY_VERIFICATION })
                } else if (nextApplicationAction === NextHomeApplicationAction.INCOME_VERIFICATION_BANK_STATEMENT) {
                    return await this.$router.replace({ path: originationPagePaths.OTHER_INCOME_VERIFICATION, query: { verificationInProgress: 'true' } })
                } else if (nextApplicationAction === NextHomeApplicationAction.INCOME_VERIFICATION_TAX_RETURN) {
                    return await this.$router.replace({ path: originationPagePaths.TAX_RETURN_VERIFICATION, query: { verificationInProgress: 'true' } })
                } else if (nextApplicationAction === NextHomeApplicationAction.INCOME_VERIFICATION_PAY_STUB) {
                    return await this.$router.replace({ path: originationPagePaths.PAY_STUB_VERIFICATION, query: { verificationInProgress: 'true' } })
                } else if (nextApplicationAction === NextHomeApplicationAction.PRE_QUALIFICATION) {
                    const isMailerOrigination = !!applicantReturningResponse.data.payload.inviteCode
                    if (isMailerOrigination) {
                        logger.log(`Changing to ${Flows.mailerOrigination} so pre-qual applicant can finish mailer origination paths`)
                        appSessionStorage.setItem(sessionStorageKey.currentFlow, Flows.mailerOrigination)
                        return await this.$router.replace({ path: mailerOriginationPagePaths.PRE_QUALIFICATION })
                    }
                    return await this.$router.replace({ path: originationPagePaths.PRE_QUALIFICATION })
                } else if (nextApplicationAction === NextHomeApplicationAction.PENDING) {
                    const isMailerOrigination = !!applicantReturningResponse.data.payload.inviteCode
                    if (isMailerOrigination) {
                        logger.log(`Changing to ${Flows.mailerOrigination} so pending applicant can finish mailer origination paths`)
                        appSessionStorage.setItem(sessionStorageKey.currentFlow, Flows.mailerOrigination)
                        return await this.$router.replace({ path: mailerOriginationFlow[0] })
                    }
                    return await this.$router.replace({ path: originationFlow[0] })
                } else if (nextApplicationAction === NextHomeApplicationAction.REVIEW) {
                    return await this.$router.replace({ path: sharedPagePaths.THANKS, query: { reason: 'review' } })
                } else if (nextApplicationAction === NextHomeApplicationAction.THANKS) {
                    return await this.$router.replace({ path: sharedPagePaths.THANKS })
                } else {
                    logger.error(`Unexpected next applicant action: ${nextApplicationAction}. Sending to thanks page`)
                    return await this.$router.replace({ path: sharedPagePaths.THANKS })
                }
            } catch (e) {
                this.errorText = ApiErrorHandler(e)
            }
        },
    }
</script>
